var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-card-details", {
    attrs: { title: _vm.nome || "Não definido" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-list-item-avatar",
              { attrs: { dark: "", color: "grey", size: "34" } },
              [
                _c("span", { staticClass: "white--text subtitle-1 mt-1" }, [
                  _vm._v(_vm._s(_vm._f("avatarName")(_vm.nome))),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "mb-4", attrs: { cols: "4" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Número"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.numero))]),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-4", attrs: { cols: "8" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.envioBoleto
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "primary",
                                small: "",
                                dark: "",
                                label: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickEnviarBoleto()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mt-1 text-uppercase" },
                                [_vm._v(" Enviar Boleto ")]
                              ),
                            ]
                          )
                        : _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "primary",
                                small: "",
                                dark: "",
                                label: "",
                              },
                            },
                            [
                              _vm.statusEmAnalise &&
                              _vm.etapa &&
                              _vm.etapa.titulo
                                ? _c(
                                    "span",
                                    { staticClass: "mt-1 text-uppercase" },
                                    [_vm._v(_vm._s(_vm.etapa.titulo))]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "mt-1 text-uppercase" },
                                    [_vm._v(_vm._s(_vm.getSubTitle()))]
                                  ),
                            ]
                          ),
                      _vm.getSubTitle().toUpperCase() === "IMPLANTADA" &&
                      ((_vm.administradora &&
                        _vm.administradora.toUpperCase() === "QUALICORP") ||
                        _vm.administradora.toUpperCase() === "ELO" ||
                        _vm.administradora.toUpperCase() === "ALIANÇA")
                        ? _c(
                            "span",
                            {
                              staticClass: "tooltip ma-1",
                              attrs: { small: "" },
                            },
                            [
                              _c("v-icon", { attrs: { size: "30" } }, [
                                _vm._v("mdi-alert-circle-outline"),
                              ]),
                              _c("span", { staticClass: "tooltip-text" }, [
                                _vm._v(
                                  "A proposta ainda será analisada pela operadora, por favor, aguarde o status "
                                ),
                                _c("b", [_vm._v("Finalizado")]),
                                _vm._v(" para notificar seu cliente."),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-col",
                  { staticClass: "mb-4", attrs: { cols: "6", md: "8" } },
                  [
                    _c("div", [
                      _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                        _vm._v("Operadora"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.operadora))]),
                    ]),
                  ]
                ),
                _c(
                  "v-col",
                  { staticClass: "mb-4", attrs: { cols: "6", md: "4" } },
                  [
                    _c("div", [
                      _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                        _vm._v("Entidade"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.entidade))]),
                    ]),
                  ]
                ),
                _vm.envioBoleto && _vm.corretora
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4", attrs: { cols: "6", md: "8" } },
                      [
                        _c("div", [
                          _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                            _vm._v(" Corretora "),
                          ]),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.corretora) + " "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.envioBoleto && _vm.vigencia && _vm.vigencia.dataVigencia
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4", attrs: { cols: "6", md: "4" } },
                      [
                        _c("div", [
                          _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                            _vm._v(" Vigência "),
                          ]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatData(_vm.vigencia.dataVigencia)
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.envioBoleto && _vm.formaPagamento
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4", attrs: { cols: "6", md: "4" } },
                      [
                        _c("div", [
                          _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                            _vm._v(" Forma de pagamento "),
                          ]),
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.formaPagamento) + " "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.envioBoleto
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4", attrs: { cols: "6", md: "8" } },
                      [
                        _vm.statusEmAnalise
                          ? _c("div", [
                              _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                                _vm._v("Passo Atual"),
                              ]),
                              _c(
                                "span",
                                { staticClass: "mt-1 text-uppercase" },
                                [_vm._v(_vm._s(_vm.getSubTitle()))]
                              ),
                            ])
                          : _c("div", [
                              _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                                _vm._v("Status da Proposta"),
                              ]),
                              _vm.etapa && _vm.etapa.titulo
                                ? _c("span", [_vm._v(_vm._s(_vm.etapa.titulo))])
                                : _vm._e(),
                            ]),
                      ]
                    )
                  : _vm._e(),
                _vm.associado
                  ? _c(
                      "v-col",
                      { staticClass: "mb-4", attrs: { cols: "6", md: "4" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mt-1",
                                                  attrs: { label: "" },
                                                },
                                                "v-chip",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Usuário associado ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3573850866
                                ),
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.associado))])]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.envioBoleto
                  ? _c(
                      "v-expansion-panels",
                      { attrs: { accordion: "", flat: "" } },
                      [
                        _c(
                          "v-expansion-panel",
                          { on: { click: _vm.onClickExpansionPanel } },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                staticClass: "subtitle-2 px-0",
                                attrs: { color: "#F7F7F7" },
                              },
                              [_vm._v(" Ver detalhes ")]
                            ),
                            _vm.formaPagamento === "Boleto" ||
                            (_vm.formaPagamento !== "Boleto" &&
                              _vm.boletos.length)
                              ? _c(
                                  "v-expansion-panel-content",
                                  {
                                    attrs: {
                                      color: "#F7F7F7",
                                      id: "custom-expansion-panel-content",
                                    },
                                  },
                                  [
                                    _vm.boletos.length
                                      ? _vm._l(
                                          _vm.boletos,
                                          function (boleto, index) {
                                            return _c(
                                              "div",
                                              { key: "boleto" + index },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          "align-self":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "my-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Boleto (" +
                                                                _vm._s(
                                                                  _vm.formatRefData(
                                                                    boleto.formatRef
                                                                  )
                                                                ) +
                                                                "): " +
                                                                _vm._s(
                                                                  boleto
                                                                    ? _vm.checkStatusBoleto(
                                                                        boleto
                                                                      )
                                                                    : "Não emitido."
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " Valor: " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    boleto.valorCobranca.valor.replace(
                                                                      ",",
                                                                      "."
                                                                    )
                                                                  )
                                                                ) +
                                                                ". "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                        attrs: {
                                                          cols: "4",
                                                          "align-self":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm
                                                                  .checkStatusBoleto(
                                                                    boleto
                                                                  )
                                                                  .substr(
                                                                    0,
                                                                    1
                                                                  ) !== "V",
                                                              color: "warning",
                                                              "x-small": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onClickCompartilhar(
                                                                  boleto
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Compartilhar "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                    _vm.boletosNaoEmitidos.length
                                      ? _vm._l(
                                          _vm.boletosNaoEmitidos,
                                          function (boletoNaoEmitido, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: "boletoNaoEmitido" + index,
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          "align-self":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "my-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Boleto: " +
                                                                _vm._s(
                                                                  boletoNaoEmitido.status
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " Valor: -- "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                        attrs: {
                                                          cols: "4",
                                                          "align-self":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled: true,
                                                              color: "warning",
                                                              "x-small": "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Compartilhar "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _c(
                                  "v-expansion-panel-content",
                                  {
                                    attrs: {
                                      color: "#F7F7F7",
                                      id: "custom-expansion-panel-content",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Boletos indisponíveis para esta proposta. "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _vm.exibirCronometro
              ? _c(
                  "label",
                  { staticClass: "mr-1" },
                  [
                    _vm.tempoParaTransmissao.tempoPrioridade === "BAIXA"
                      ? _c(
                          "v-icon",
                          { attrs: { color: "green", size: "20" } },
                          [_vm._v("mdi-clock-time-twelve-outline")]
                        )
                      : _vm.tempoParaTransmissao.tempoPrioridade === "MEDIA"
                      ? _c(
                          "v-icon",
                          { attrs: { color: "yellow", size: "20" } },
                          [_vm._v("mdi-clock-time-twelve-outline")]
                        )
                      : _vm.tempoParaTransmissao.tempoPrioridade === "ALTA"
                      ? _c("v-icon", { attrs: { color: "red", size: "20" } }, [
                          _vm._v("mdi-clock-time-twelve-outline"),
                        ])
                      : _c("v-icon", { attrs: { color: "red", size: "20" } }, [
                          _vm._v("mdi-alarm-off"),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.$vuetify.breakpoint.mobile
              ? _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.descricaoPermitidoTransmissao.responsivo
                    ),
                  },
                })
              : _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.descricaoPermitidoTransmissao.mobile),
                  },
                }),
            _c("v-spacer"),
            _c(
              "span",
              { staticClass: "subtitle-1 font-weight-bold primary--text" },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.valorTotalOdontoAdicional
                        ? _vm.valor + _vm.valorTotalOdontoAdicional
                        : _vm.valor
                    )
                  )
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }