import api from '@/services/api';
import AppError from "@/utils/appError";

const resource = "boleto";

const buscarBoletos = async (nrProposta) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_BOLETOS } };
    const { data } = await api.get(`${resource}/${nrProposta}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const enviarBoletoEmail = async (body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_BOLETOS } };
    const { data } = await api.post(`${resource}/email`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const enviarBoletoEmailSAP = async (body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_BOLETOS } };
    const { data } = await api.post(`${resource}/email/sap`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const enviarBoletoWhatsapp = async (body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_BOLETOS } };
    const { data } = await api.post(`${resource}/whatsapp`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  buscarBoletos,
  enviarBoletoEmail,
  enviarBoletoEmailSAP,
  enviarBoletoWhatsapp,
};
