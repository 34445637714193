<template>
   <v-card class="mx-auto" elevation="3">
    <v-list-item class="pt-0 pb-0">
      <slot name="header"></slot>
      <v-list-item-content class="flex-grow-1" style="flex-basis: auto;">
        <v-list-item-title class="subtitle-2 mt-1 text-wrap text-uppercase" style="line-height: 1.3">{{title}}</v-list-item-title>
      </v-list-item-content>
      <v-spacer></v-spacer>
      <slot name="header-right"></slot>
    </v-list-item>
    <v-divider></v-divider>

    <v-card color="#F7F7F7" flat tile class="pa-2 pl-4 pr-4">
      <slot name="body"></slot>
    </v-card>
    <v-divider></v-divider>
    <v-card-actions :class="footerColor">
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    footerColor: {
      required: false,
      default: 'white',
    },
  },
};
</script>
