var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { attrs: { dark: "", dense: "", color: "primary", fixed: "" } },
    [
      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
      _c("v-spacer"),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }