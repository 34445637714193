<template>
  <v-toolbar dark dense color="primary" fixed>
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
    },
  },
};
</script>

<style>

</style>
